import { Flex, Heading } from "@chakra-ui/react";
import { h_700_16_100_32 } from "@/styles/fontStyles";

export const BlueTextBoxArrow = ({
  title,
  heading = "h4",
  headingStyles,
  boxStyles,
}) => {
  return (
    <Flex
      maxW={"fit-content"}
      h={"30px"}
      color={"dashboardBackground"}
      bgColor={"mainBlue"}
      pt={"6px"}
      px={"20px"}
      clipPath={
        "polygon(0 0, calc(100% - 14px) 0, 100% 50%, calc(100% - 14px) 100%, 0 100%, 0 0)"
      }
      {...boxStyles}
    >
      <Heading as={heading} {...h_700_16_100_32} mr={"14px"} {...headingStyles}>
        {title}
      </Heading>
    </Flex>
  );
};
