// Helvetica LT Pro
export const h_700_24_normal = {
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: 'normal',
  letterSpacing: '0.48px',
};

export const h_700_16_16 = {
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '100%',
};

export const h_700_14_16 = {
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '120%',
  letterSpacing: '0.28px',
};

export const h_700_21_normal_42 = {
  fontWeight: '700',
  fontSize: '21px',
  lineHeight: 'normal',
  letterSpacing: '0.42px',
};

export const h_700_16_100_32 = {
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '100%',
  letterSpacing: '0.32px',
};

export const h_700_16_100_64 = {
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '100%',
  letterSpacing: '0.64px',
};

export const h_700_16_100_64_trim = {
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '100%',
  letterSpacing: '0.64px',
};

export const h_700_14_100 = {
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '100%',
};

export const h_700_14_100_28 = {
  ...h_700_14_100,
  letterSpacing: '0.28px',
};

export const h_700_14_120_28 = {
  ...h_700_14_100_28,
  lineHeight: '120%',
};

export const h_700_24_normal_48 = {
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: 'normal',
  letterSpacing: '0.48px',
};

export const h_700_12_100 = {
  fontWeight: '700',
  fontSize: '12px',
  lineHeight: '100%',
};

export const h_700_12_100_24 = {
  ...h_700_12_100,
  letterSpacing: '0.24px',
};

export const h_700_12_100_24_trim = {
  fontWeight: '700',
  fontSize: '12px',
  lineHeight: '100%',
  letterSpacing: '0.24px',
};

export const h_700_12_100_48 = {
  fontWeight: '700',
  fontSize: '12px',
  lineHeight: '100%',
  letterSpacing: '0.48px',
};

export const h_700_10_100 = {
  fontWeight: '700',
  fontSize: '10px',
  lineHeight: '100%',
};
export const h_700_10_135 = {
  fontWeight: '700',
  fontSize: '10px',
  lineHeight: '135%',
};
export const h_700_7_135 = {
  fontWeight: '700',
  fontSize: '10px',
  lineHeight: '100%',
};

export const h_400_16_normal_32 = {
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: 'normal',
  letterSpacing: '0.32px',
};

export const h_700_40_150 = {
  fontWeight: '700',
  fontSize: '40px',
  lineHeight: '150%',
};

export const h_700_72_150 = {
  fontWeight: '700',
  fontSize: '72px',
  lineHeight: '150%',
};

export const h_400_16_normal_20 = {
  // fontWeight: '400',
  // fontSize: '16px',
  // lineHeight: '125%',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '125%',
};

export const h_400_16_100 = {
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '100%',
};

export const h_400_14_100_64 = {
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '135%',
  letterSpacing: '0.64px',
};
export const h_400_28_100_28 = {
  fontWeight: '400',
  fontSize: '28px',
  lineHeight: '100%',
};

export const h_400_14_normal_28 = {
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: 'normal',
  letterSpacing: '0.28px',
};

export const h_400_14_135_28 = {
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '135%',
  letterSpacing: '0.28px',
};

export const h_400_14_135_28_trim = {
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '135%',
  letterSpacing: '0.28px',
};

export const h_400_14_135_56 = {
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '135%',
  letterSpacing: '0.56px',
};

export const h_400_12_100_48_trim = {
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '100%',
  letterSpacing: '0.48px',
};
export const h_400_12_100_48 = {
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '100%',
  letterSpacing: '0.48px',
};

export const h_400_12_135_48 = {
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '135%',
  letterSpacing: '0.48px',
};

export const h_400_16_135_64 = {
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '135%',
  letterSpacing: '0.64px',
};
export const h_400_16_135 = {
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '135%',
};

export const h_400_10_135_04 = {
  fontWeight: '400',
  fontSize: '10px',
  lineHeight: '135%',
  letterSpacing: '0.4px',
};
export const h_700_20_100_04 = {
  fontFamily: 'Helvetica',
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: '100%',
  letterSpacing: '0.4px',
};
export const h_700_20_normal = {
  fontFamily: 'Helvetica',
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: 'normal',
};
export const h_700_28_100 = {
  fontFamily: 'Helvetica',
  fontWeight: '700',
  fontSize: '28px',
  lineHeight: '100%',
};
export const h_400_21_100 = {
  fontFamily: 'Helvetica',
  fontWeight: '400',
  fontSize: '21px',
  lineHeight: '100%',
};
export const h_400_12_160 = {
  fontFamily: 'Helvetica',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '160%',
};

export const h_700_15_100_03 = {
  fontFamily: 'Helvetica',
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '100%',
  letterSpacing: '0.3px',
};
export const h_400_13_100_0524 = {
  fontFamily: 'Helvetica',
  fontWeight: '400',
  fontSize: '13px',
  lineHeight: '100%',
  letterSpacing: '0.524px',
  leadingTrim: 'both',
  textEdge: 'cap',
};
export const h_700_9_100_0185 = {
  fontFamily: 'Helvetica',
  fontWeight: '700',
  fontSize: '9px',
  lineHeight: '100%',
  letterSpacing: '0.185px',
};
export const h_700_7_100_142 = {
  fontFamily: 'Helvetica',
  fontWeight: '700',
  fontSize: '7px',
  lineHeight: '100%',
  letterSpacing: '0.142px',
};

export const h_700_3_100_073 = {
  fontFamily: 'Helvetica',
  fontWeight: '700',
  fontSize: '3.63px',
  lineHeight: '100%',
  letterSpacing: '0.073px',
};

export const h_700_6_120_124 = {
  fontFamily: 'Helvetica',
  fontWeight: '700',
  fontSize: '6px',
  lineHeight: '120%',
  letterSpacing: '0.124px',
};

export const h_700_3_120_064 = {
  fontFamily: 'Helvetica',
  fontWeight: '700',
  fontSize: '3.177px',
  lineHeight: '120%',
  letterSpacing: '0.064px',
};

export const h_400_6_135_248 = {
  fontFamily: 'Helvetica',
  fontWeight: '400',
  fontSize: '6.2px',
  lineHeight: '135%',
  letterSpacing: '0.248px',
};

export const h_400_3_135_127 = {
  fontFamily: 'Helvetica',
  fontWeight: '400',
  fontSize: '3.177px',
  lineHeight: '135%',
  letterSpacing: '0.127px',
};

export const h_400_5_135_213 = {
  fontFamily: 'Helvetica',
  fontWeight: '400',
  fontSize: '5.314px',
  lineHeight: '135%',
  letterSpacing: '0.213px',
};
export const h_700_25_100_5 = {
  fontFamily: 'Helvetica',
  fontWeight: '700',
  fontSize: '25px',
  lineHeight: '100%',
  letterSpacing: '0.5px',
};

export const h_700_14_normal_28 = {
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: 'normal',
  letterSpacing: '0.281px',
};

export const h_400_7_100_141 = {
  fontWeight: '400',
  fontSize: '7px',
  lineHeight: '100%',
  letterSpacing: '0.141px',
};

export const h_700_8_100_164 = {
  fontFamily: 'Helvetica',
  fontWeight: '700',
  fontSize: '8.2px',
  lineHeight: '100%',
  letterSpacing: '0.164px',
};

export const h_700_16_150 = {
  fontFamily: 'Helvetica',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '150%',
};

export const h_400_16_150 = {
  fontFamily: 'Helvetica',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '150%',
};

export const h_400_25_normal = {
  fontFamily: 'Helvetica',
  fontWeight: '400',
  fontSize: '25.5px',
  lineHeight: 'normal',
};

// Roslindale

export const r_400_54_normal = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '3.375em', //54px
  lineHeight: 'normal',
};
export const r_400_58_normal = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '58.5px', //54px
  lineHeight: 'normal',
};
export const r_400_46_normal = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '46px', //46px
  lineHeight: 'normal',
};
export const r_400_44_normal = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '44px',
  lineHeight: 'normal',
};
export const r_400_24_normal = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '24px',
  lineHeight: 'normal',
};
export const r_400_24 = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '24px',
  lineHeight: 'normal',
  fontStyle: 'normal',
};
export const r_400_24_150 = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '24px',
  lineHeight: '150%',
  fontStyle: 'normal',
};

export const r_400_20 = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '20px',
  lineHeight: '85%',
};

export const r_400_16_130 = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '130%',
};

export const r_400_14_normal = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: 'normal',
};

export const r_400_80_120 = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '80px',
  lineHeight: '120%',
  letterSpacing: '0.2px',
};
export const r_400_50_120 = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '50px',
  lineHeight: '120%',
  letterSpacing: '0.2px',
};
export const r_400_40_normal = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '40px',
  lineHeight: 'normal',
};
export const r_400_14_150 = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '150%',
};
export const r_400_48_normal = {
  fontFamily: 'Roslindale',
  fontWeight: '400',
  fontSize: '48px',
  lineHeight: 'normal',
};

// Urbanist

export const u_600_40_180 = {
  fontFamily: 'Urbanist',
  fontWeight: '600',
  fontSize: '40px',
  lineHeight: '180%',
  fontStyle: 'normal',
};

export const u_600_14_100 = {
  fontFamily: 'Urbanist',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '100%',
  fontStyle: 'normal',
};

// Bitter

export const b_400_14_150 = {
  fontFamily: 'Bitter',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '150%',
  fontStyle: 'normal',
};

// Open Sans

export const o_400_14_135_28 = {
  fontFamily: 'Open Sans',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '135%',
  letterSpacing: '0.28px',
};

export const o_400_12_100_48 = {
  fontFamily: 'Open Sans',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '100%',
  letterSpacing: '0.48px',
};

export const o_700_12_100_24 = {
  fontFamily: 'Open Sans',
  fontWeight: '700',
  fontSize: '12px',
  lineHeight: '100%',
  letterSpacing: '0.24px',
};

export const o_700_14_120_28 = {
  fontFamily: 'Open Sans',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '120%',
  letterSpacing: '0.28px',
};

export const o_400_12_100 = {
  fontFamily: 'Open Sans',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '100%',
  letterSpacing: '0px',
};

export const o_400_16_100 = {
  fontFamily: 'OpenSans',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '100%',
  letterSpacing: '0px',
};

export const o_400_16_135 = {
  fontFamily: 'OpenSans',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '135%',
  letterSpacing: '0px',
};
