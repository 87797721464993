'use client';

import { Flex } from '@chakra-ui/react';

export const MainWrapper = ({
  children,
  customStyleOuter,
  customStyleInner,
}) => {
  return (
    <Flex
      w={'full'}
      h={'fit-content'}
      flexDir={'column'}
      align={'center'}
      px={{ base: '0px', md: '40px', lg: '40px' }}
      {...customStyleOuter}
    >
      <Flex
        flexDir={'column'}
        w={'full'}
        h={'fit-content'}
        maxW={'1360px'}
        {...customStyleInner}
      >
        {children}
      </Flex>
    </Flex>
  );
};
