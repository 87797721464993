import { Flex, Input, InputGroup } from "@chakra-ui/react";
import SearchSvg from "@/assets/svg/search.svg";
import { h_400_16_135 } from "@/styles/fontStyles";

export const SearchInput = ({
  customStyleInputGroup,
  customStyleInput,
  placeholder,
  data,
  setData,
  IconLeft,
  IconRight,
  NotIcon,
  handlerKeyDown = () => {},
  onClickIconLeft = () => {},
  SendIcon,
}) => {
  return (
    <Flex
      w={"100%"}
      alignItems={"center"}
      maxW={{ base: "100%", md: "660px" }}
      minH={"26px"}
      maxH={"50px"}
      border={"1.5px solid"}
      borderColor={"borderGray"}
      borderRadius={"10px"}
      columnGap={"8px"}
      px={"16px"}
      bgColor="dashboardBackground"
      {...customStyleInputGroup}
    >
      {NotIcon ? null : IconLeft ? (
        <IconLeft onClick={(e) => onClickIconLeft(e)} />
      ) : (
        <SearchSvg onClick={(e) => onClickIconLeft(e)} cursor={"pointer"} />
      )}
      <Input
        variant={"unstyled"}
        {...h_400_16_135}
        type="text"
        name={"search"}
        fontFamily={"Roboto"}
        placeholder={placeholder || "Search"}
        focusBorderColor="transparent"
        borderRadius={"none"}
        border={"none"}
        p={"0px"}
        _placeholder={{
          color: "gray",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "1.3",
          letterSpacing: "2%",
        }}
        value={data}
        onChange={(e) => {
          setData(e.target.value);
        }}
        {...customStyleInput}
        onKeyDown={(e) => handlerKeyDown(e)}
      />
      {IconRight ? <IconRight /> : ""}
      {SendIcon || null}
    </Flex>
  );
};
