"use client";

import { Flex } from "@chakra-ui/react";
import {
  h_400_12_100_48_trim,
  h_700_14_100,
  h_700_14_120_28,
  h_400_10_135_04,
} from "@/styles/fontStyles";

export const CustomElement = ({
  title, //title - title
  variant, // variant = 'blueBtn' | 'redBtn' | 'outlineBtn' | 'backBtn' | 'seeMoreBtn' | 'whiteBtn' | 'whiteBtnOutline' | 'smallRedBtn' | 'smallBlueBtn' | 'smallGreenBtn' | 'smallGrayBtn'
  isDisabled = false, // isDisabled - isDisabled
  // isLoading = false, // isLoading - isLoading
  children, // children - children
  handler, // handler - handler
  customStyle, // customStyle={{ w: 'fit-content', h: '33px', ... and so }}
}) => {
  const baseStyle = {
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };

  const colorAndOutLineBtnStyle = {
    ...baseStyle,
    ...h_700_14_100,
    p: "15px 24px 12px 24px",
  };

  const colorBtnStyle = {
    ...colorAndOutLineBtnStyle,
    color: !isDisabled ? "white" : "disabledColor",
    bgColor: !isDisabled ? "mainBlue" : "disabledBg",
    pointerEvents: isDisabled ? "none" : "auto",
    p: "18px 24px 14px 24px",
    _hover: {
      bgColor: !isDisabled ? "blue" : "disabledBg",
    },
    _disabled: {
      bgColor: "disabledBg",
      color: "disabledColor",
    },
  };

  const colorSmallBtnStyle = {
    ...h_400_10_135_04,
    color: "white",
    p: "8px 8px 4px 8px",
    borderRadius: "4px",
  };

  const outlineBtnStyle = {
    ...baseStyle,
    ...colorAndOutLineBtnStyle,
    color: "gray",
    bgColor: "transparent",
    border: "1px solid",
    borderColor: "gray",
    _hover: {
      color: "mainBlue",
      bgColor: "transparent",
      borderColor: "mainBlue",
    },
  };

  const textBtnStyle = {
    ...baseStyle,
    ...h_700_14_100,
    w: "86px",
    h: "46px",
    color: "gray",
    bgColor: "transparent",
    _hover: { color: "mainBlue", bgColor: "transparent" },
  };

  const whiteBtnStyle = {
    ...baseStyle,
    w: "fit-content",
    h: "32px",
    bgColor: "white",
    color: "naturalBlack",
    p: "14px 20px 12px 20px",
    ...h_400_12_100_48_trim,
    _hover: {
      bgColor: "white",
      boxShadow: "0px 0px 14px 0px rgba(114, 114, 114, 0.25)",
      // "0px 0.10563px 2.73498px 0px rgba(141, 141, 141, 0.09), 0px 0.32749px 10.32032px 0px rgba(141, 141, 141, 0.12), 0px 0.85182px 25.284px 0px rgba(141, 141, 141, 0.13), 0px 2.11393px 53.43982px 0px rgba(141, 141, 141, 0.15), 0px 5px 117px 0px rgba(141, 141, 141, 0.23)",
    },
  };

  let variantStyle;

  switch (variant) {
    case "blueBtn":
      variantStyle = { ...colorBtnStyle, ...h_700_14_120_28 };
      break;
    case "redBtn":
      variantStyle = {
        ...colorBtnStyle,
        bgColor: "red",
        _hover: { bgColor: "darkRedNew" },
      };
      break;
    case "smallRedBtn":
      variantStyle = { ...colorSmallBtnStyle, bgColor: "red" };
      break;
    case "smallBlueBtn":
      variantStyle = { ...colorSmallBtnStyle, bgColor: "mainBlue" };
      break;
    case "smallGreenBtn":
      variantStyle = { ...colorSmallBtnStyle, bgColor: "green" };
      break;
    case "smallGrayBtn":
      variantStyle = { ...colorSmallBtnStyle, bgColor: "gray" };
      break;
    case "smallWhiteBtn":
      variantStyle = { ...colorSmallBtnStyle, bgColor: "white" };
      break;
    case "outlineBtn":
      variantStyle = { ...outlineBtnStyle };
      break;
    case "backBtn":
      variantStyle = {
        ...textBtnStyle,
        color: "blackColor",
        _hover: { color: "blue" },
      };
      break;
    case "seeMoreBtn":
      variantStyle = { ...textBtnStyle };
      break;
    case "whiteBtn":
      variantStyle = { ...whiteBtnStyle };
      break;
    case "whiteBtnOutline":
      variantStyle = {
        ...whiteBtnStyle,
        border: "1.5px solid",
        borderColor: "menuGray",
      };
      break;
  }
  return (
    <Flex
      {...variantStyle}
      transition={"all 0.25s ease-in-out"}
      onClick={handler}
      whiteSpace={"nowrap"}
      {...customStyle}
    >
      {title && title}
      {children && children}
    </Flex>
  );
};
